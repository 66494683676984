<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center content-wrapper" id="page-login">
    <div class="vx-col w-11/12 md:w-4/5 lg:w-1/2">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
           <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col w-full py-8 px-12 md:p-0 md:w-1/2 text-center">
              <img v-if="appName === 'FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="65%" height="auto" alt="Flippay" class="mx-auto"/>
              <img v-else-if="appName === 'RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="65%" height="auto" alt="Rellopay" class="mx-auto"/>
              <div class="vx-card__title text-center pt-6 md:hidden">
                <h3 class="mb-4 text-xl md:text-2xl">Recover your password</h3>
                <p class="font-light">Please enter your email address and we'll send you instructions on how to reset your password.</p>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2">
              <div class="pt-2 pb-4 sm:pb-6 px-6 md:pt-10 md:pb-12 md:px-10 login-tabs-container bg-white">
                <div class="vx-card__title mb-12 md:mb-4 text-center md:text-left hidden md:block">
                  <h3 class="mb-4 text-xl md:text-2xl">Recover your password</h3>
                  <p class="font-light">Please enter your email address and we'll send you instructions on how to reset your password.</p>
                </div>

                <div class="mb-8">
                  <label class="w-full font-light">Email</label>
                  <vs-input v-validate="'required|email|min:3'" data-vv-validate-on="blur" name="email" v-model="user.email" class="w-full mb-1 r-0"/>
                  <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                </div>

               <div class="flex flex-col">
                 <vs-button size="medium" v-round @click="recoverPassword" class="w-full round mb-6">Recover password</vs-button>
                 <vs-button size="medium" v-round @click="goToLogin" class="round w-full only-border-btn">Back to login</vs-button>
               </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  export default {
    data() {
      return {
        user: {
          email: '',
        },
        showDismissibleAlert: false,
        message: '',
        loginError: '',
        appName: process.env.VUE_APP_NAME,
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.email != '';
      },
    },
    methods: {
      ...mapActions('auth', ['forgotPwd']),
      goToLogin() {
        this.$router.push('/login')
      },
      recoverPassword() {
        const payload = {
          userDetail: this.user
        }
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.loading();
            this.forgotPwd(payload)
              .then((response) => {
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Message',
                  text: response.data.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'green',
                });
              }).catch((error) => {
                this.showErrorDismissibleAlert = true
                this.message = error.message
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Error',
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger',
                });
              });
          }
        }).catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error Message',
            text: error,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        });
      }
    }
  }
</script>

